import React from "react";
import Seo from "../../components/seo";
import Layout from "../../Layout";
import Breadcrumbs from "../../components/Breadcrumbs";
import Image1 from "./images/beens1.jpg";
import Image2 from "./images/beens2.jpg";
import Image3 from "./images/beens3.jpg";
import Image4 from "./images/beens4.jpg";
import Image5 from "./images/beens5.png";
import Image6 from "./images/beens6.jpg";
import FarmImage1 from "./images/farm1.jpg";
import FarmImage2 from "./images/farm2.jpg";

const images = [Image1, Image2, Image3, Image4, Image5, Image6];

const greenBeanBenefits = [
  {
    text: "Rich in Nutrients",
    details:
      "High in vitamins A, C, K, and folate, as well as minerals like iron and potassium.",
  },
  {
    text: "Supports Immunity",
    details:
      "Packed with antioxidants and vitamin C to help boost your immune system.",
  },
  {
    text: "Promotes Digestion",
    details:
      "High in fiber, which aids digestion and helps prevent constipation.",
  },
  {
    text: "Supports Bone Health",
    details:
      "A good source of vitamin K, crucial for bone strength and calcium absorption.",
  },
  {
    text: "Stabilizes Blood Sugar",
    details:
      "Low glycemic index and fiber content help regulate blood sugar levels.",
  },
];

const GreenBeanPage = () => (
  <Layout>
    <Seo title="Green Beans" />
    <Breadcrumbs title="Green Beans" />
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8">
          <style>
            {`
              .fixed-size { width: 150px; height: 150px; object-fit: cover; margin: 3px; }
              .narrow-paragraph { max-width: 700px; margin: 0 auto; }
              .custom-table { width: 100%; margin-top: 20px; border-collapse: collapse; }
              .custom-table th, .custom-table td { border: 1px solid #ddd; padding: 8px; }
              .custom-table th { background-color: #f2f2f2; text-align: left; }
              .custom-table .green-header { background-color: #d4edda; }
              .margin-bottom { margin-bottom: 50px; }
              .farm-description { display: flex; align-items: center; margin-bottom: 15px; }
              .farm-description img { margin-right: 15px; width: 150px; height: 150px; object-fit: cover; }

              @media (max-width: 767px) {
                .narrow-paragraph { padding: 0 15px; }
                .farm-description { flex-direction: column; text-align: center; }
                .farm-description img { margin: 0 0 15px 0; }
                .custom-table th, .custom-table td { padding: 4px; }
                .margin-bottom { margin-bottom: 30px; }
              }
            `}
          </style>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row">
                {images.slice(0, 3).map((image, index) => (
                  <div className="col-4" key={index}>
                    <img
                      src={image}
                      alt={`Green bean field ${index + 1}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-2">
                {images.slice(3).map((image, index) => (
                  <div className="col-4" key={index + 3}>
                    <img
                      src={image}
                      alt={`Green bean field ${index + 4}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-3 narrow-paragraph">
                <div className="col-12">
                  <p>
                    Green beans, also known as string beans, are a popular
                    vegetable found in kitchens around the world. They belong to
                    the legume family and are commonly harvested while still
                    immature, giving them their tender, crisp texture and
                    vibrant green color. Green beans are packed with essential
                    nutrients and can be enjoyed in a variety of dishes, from
                    salads and stir-fries to stews and casseroles.
                  </p>
                  <p>
                    Opting for organic green beans ensures you're getting the
                    best of nature without the harmful chemicals and pesticides
                    used in conventional farming. Organic farming methods
                    prioritize sustainability, environmental health, and soil
                    quality, which means you're not only eating healthier but
                    also supporting a system that preserves our planet's
                    resources.
                  </p>
                </div>
              </div>
              <h3>
                <strong>Our Organic Farms</strong>
              </h3>
              <div className="farm-description">
                <img
                  src={FarmImage1}
                  alt="Organic Farm 1"
                  className="img-thumbnail"
                />
                <p>Farm at Nuwara Eliya Control Union Certified</p>
              </div>
              <div className="farm-description">
                <img
                  src={FarmImage2}
                  alt="Organic Farm 2"
                  className="img-thumbnail"
                />
                <p>Farm at Chilaw PGS Certified</p>
              </div>
            </div>
            <div className="col-12 col-md-4 ml-auto">
              <h2>Health Benefits of Organic Green Beans</h2>
              <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                {greenBeanBenefits.map((benefit, index) => (
                  <li key={index}>
                    <span style={{ color: "green" }}>✅</span>
                    <span style={{ color: "black" }}>
                      <strong>{benefit.text}:</strong> {benefit.details}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <h3>
            <strong>Nutrition Table</strong>
          </h3>
          <table className="custom-table margin-bottom">
            <thead>
              <tr>
                <th className="green-header">Nutrient</th>
                <th className="green-header">
                  Amount (per 1 cup, about 125 grams, raw)
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Calories</td>
                <td>31 kcal</td>
              </tr>
              <tr>
                <td>Protein</td>
                <td>2.4 g</td>
              </tr>
              <tr>
                <td>Fat</td>
                <td>0.1 g</td>
              </tr>
              <tr>
                <td>Carbohydrates</td>
                <td>7.1 g</td>
              </tr>
              <tr>
                <td>Fiber</td>
                <td>3.4 g</td>
              </tr>
              <tr>
                <td>Sugars</td>
                <td>3.6 g</td>
              </tr>
              <tr>
                <td>Vitamin A</td>
                <td>9% of DV</td>
              </tr>
              <tr>
                <td>Vitamin C</td>
                <td>20% of DV</td>
              </tr>
              <tr>
                <td>Vitamin K</td>
                <td>22% of DV</td>
              </tr>
              <tr>
                <td>Folate</td>
                <td>11% of DV</td>
              </tr>
              <tr>
                <td>Iron</td>
                <td>6% of DV</td>
              </tr>
              <tr>
                <td>Magnesium</td>
                <td>6% of DV</td>
              </tr>
              <tr>
                <td>Potassium</td>
                <td>211 mg</td>
              </tr>
            </tbody>
          </table>
          <p>DV = Daily Value based on a 2,000-calorie diet</p>
        </div>
      </div>
    </div>
  </Layout>
);

export default GreenBeanPage;
